import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";

const CtaBanner = ({ headline, url, btnText }) => {
	return (
		<section className="bg-light py-5 py-6">
			<Container>
				<Row className="align-items-center">
					<Col
						xs={12}
						lg={8}
						className="mb-4 mb-md-0 text-start text-md-center text-lg-start"
					>
						<h4>{headline}</h4>
					</Col>
					<Col xs={12} lg={4} className="text-center">
						<Button
							className="me-lg-auto float-lg-end cta-btn"
							as={Link}
							to={url}
							variant="primary"
						>
							{btnText}
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default CtaBanner;
