import React from "react";
import Layout from "../../components/layout";
import CtaBanner from "../../components/cta-banner";
import Review from "../../components/review";
import ServicesHero from "../../components/services/hero";
import PhotoLeft from "../../components/services/photo-left";
import PhotoRight from "../../components/services/photo-right";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const CreativeManagementPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: file(relativePath: { eq: "services/creative.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			lampImg: file(relativePath: { eq: "services/creative/lampshade.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			bathroomImg: file(
				relativePath: { eq: "services/creative/bathroom.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Creative Management Photography",
				item: `${siteUrl}/services/creative-management`,
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Social Media Photographer in Sussex | Sarah Bennett"
				description="Management of your images and social media feeds"
				language="en"
				canonical="https://www.sarah-bennett.com/services/creative-management"
				openGraph={{
					url: "https://www.sarah-bennett.com/services/creative-management",
					title:
						"Professional Social media photographer in East & West Sussex | Sarah Bennett Commercial Photography",
					description: "Management of your images and social media images",
					images: [
						{
							url: `${data.heroImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Experienced Social Media Photographer in East and West Sussex",
						},
					],
				}}
			/>
			<ServicesHero
				headline="Social Media Photographer"
				img={data.heroImg.childImageSharp.gatsbyImageData}
				showBtn={false}
			/>
			<PhotoLeft
				img={data.lampImg.childImageSharp.gatsbyImageData}
				heading="Be original!"
				text="Do you find that your social media imagery is a little sad, a bit repetitive and full of bad quality images? Do you run out of ideas or don't have the time to create new images? Do you know what to post? Having someone take management of the creative process might be the way forward. Consumers these days will spot a recurring, or stock photo a mile off!"
			/>
			<PhotoRight
				img={data.bathroomImg.childImageSharp.gatsbyImageData}
				heading="What if you could?"
				text="Have a commercial photographer not only create your own stock photography for you on a monthly basis, but also write captions and post onto your social feeds for you? Images that can fill your feed, be in keeping with your current marketing plans and aims, and that really represent your brand. If this sounds like the answer, then get in contact with me and let's discuss how we can get your social media working for you!"
				cta="Work with Sarah"
				ctaUrl="/contact-us"
			/>
			<Review
				author="Paula Pollard"
				company="Hashtag Clean"
				quote="We booked Sarah for a company shoot for both our website and social media pics. After showing great flexibility with a change in dates the two days we had with Sarah went really well and the whole experience was seamless and actually, a lot of fun! Sarah is super easy to work with; taking our ideas on board and having some great ones herself. Sarah put our staff completely at ease as they weren't that keen on having their pictures taken initially. The pics themselves are exactly what we wanted, couldn't be happier. A great investment as we now have a huge library to choose from - I would highly recommend."
			/>
			<CtaBanner
				headline="Book an appointment with me to discuss your social media photography"
				btnText="Book an appointment"
				url="/contact-us"
			/>
		</Layout>
	);
};

export default CreativeManagementPage;
