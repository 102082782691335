import Link from "../link";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";

const ServicesHero = ({
	headline,
	subheadline,
	url,
	img,
	showBtn,
	btnText,
}) => {
	const btnUrl = url || "/contact-us#quote-form";
	const buttonText = btnText || "Get a Quote";

	return (
		<>
			<section className="my-lg-5">
				<Container>
					<Row className="align-items-md-center mb-md-5">
						<Col
							xs={12}
							md={6}
							className="text-center order-1 order-lg-0 my-8 my-md-0"
						>
							<h1 className="mb-3">{headline}</h1>
							<p classname="mt-5">{subheadline}</p>
							<Button
								className="mt-4 cta-btn"
								variant="primary"
								as={Link}
								to={btnUrl}
								style={{ display: showBtn ? "inline-block" : "none" }}
							>
								{buttonText}
							</Button>
						</Col>
						<Col xs={12} md={6} className="order-0 order-lg-1">
							<GatsbyImage image={img} />
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

ServicesHero.defaultProps = {
	url: "/contact-us",
	showBtn: true,
};

// ServicesHero.propTypes = {
//     headline: PropTypes.element.isRequired,
//     subheadline: PropTypes.element.isRequired
// }

export default ServicesHero;
